class ColorSwatch extends HTMLElement {
    constructor() {
      super();
      this.handle = this.getAttribute('handle');
      this.sectionId = this.getAttribute('section-id')
      this.variantRadios = document.querySelector('variant-radios');
      this.input = this.querySelector('input');

      this.addEventListener('change', this.onColorUpdate.bind(this))
    }

      onColorUpdate(event) {
        event.preventDefault();
        fetch(`/products/${this.handle}?section_id=${this.sectionId}`)
          .then((response) => response.text())
          .then((responseText) => {
            const html = new DOMParser().parseFromString(responseText, 'text/html').querySelector(".js-product-main");
            let fetchColorSwatches = html.querySelector('#ColorVariants');
            document.querySelector(`color-swatch[handle="${this.handle}"]`).checked = true;
            let currentRadios = document.querySelector('#ColorVariants');
            currentRadios.querySelectorAll('input').forEach((input => {
            }))      
            
            let selectedSwatch = html.querySelector('.js-selected-option-color');
            let currentSwatch = document.querySelector('.js-selected-option-color');
            console.log(selectedSwatch.innerHTML, currentSwatch.innerHTML)
            currentSwatch.innerHTML = selectedSwatch.innerHTML;

            fetchColorSwatches.innerHTML = currentRadios.innerHTML;
            document.querySelector('.js-product-main').innerHTML = html.innerHTML;
            document.querySelector(`color-swatch[handle="${this.handle}"]`).querySelector('input').checked = true;
            
            window.history.replaceState({}, '', `/products/${this.handle}`);
          })
          .catch((e) => {
            console.error(e);
          });
      }
    
  }
  
  customElements.define('color-swatch', ColorSwatch);
  